import { Component, inject, ViewChild } from '@angular/core';
import { Location, NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Campaign } from '@admin/models/Campaign.model';
import { CampaignSeason } from '@admin/models/Playoffs.model';
import { MonthlySummaryComponent } from '@admin/campaigns/components/campaigns-types/monthly-summary/monthly-summary.component';
import { EarnedMediaComponent } from '@admin/campaigns/components/campaigns-types/earned-media/earned-media.component';
import { FieldboardsComponent } from '@admin/campaigns/components/campaigns-types/fieldboards/fieldboards.component';
import { NearGoalComponent } from '@admin/campaigns/components/campaigns-types/near-goal/near-goal.component';
import { Segment } from '@admin/models/Segment.model';
import { Fieldboard } from '@admin/models/Fieldboard.model';
import { EarnedMedia } from '@admin/models/EarnedMedia.model';
import { NearGoal } from '@admin/models/NearGoal.model';
import { Budgets } from '@admin/models/CampaignType.model';
import { FormComponent, TitleComponent } from '@shared/components';

export class StepData {
  step: number = -1;
  actions: string[] = [];
  subtitle: string = '';
}

class SegmentData {
  fieldboard: Fieldboard = new Fieldboard();
  keyMoments: Fieldboard = new Fieldboard();
  nearGoal: NearGoal = new NearGoal();
  earnedMedia: EarnedMedia = new EarnedMedia();
}

@Component({
  selector: 'app-segment-form',
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    TitleComponent,
    MonthlySummaryComponent,
    FieldboardsComponent,
    NearGoalComponent,
    EarnedMediaComponent,
  ],
  templateUrl: './segment-form.component.html',
  styleUrl: './segment-form.component.scss',
})
export class SegmentFormComponent extends FormComponent {
  location = inject(Location);
  route = inject(ActivatedRoute);
  router = inject(Router);

  override data: CampaignSeason = new CampaignSeason();
  // ! CAMPAIGN TYPE FOR PLAYOFFS AND REGULAR SEASON
  // ! TypeId for [ 1 = regular season, 2 = playoffs] data
  title: string = 'CLIENT NAME / CAMPAIGN NAME';
  subtitle: string = '';
  campaign: Campaign = new Campaign();
  items: any[] = [];
  step: number = 0;
  stepData: StepData = new StepData();
  campaignId: number = -1;
  typeId: number = -1;
  type: string = '';
  segmentId: number = -1;
  segment: Segment = new Segment();
  segmentData = new SegmentData();

  // * STEP 1
  @ViewChild('fieldboards') fieldsboards?: FieldboardsComponent;
  // * STEP 2
  @ViewChild('keyMoments') keyMoments?: FieldboardsComponent;
  // * STEP 3
  @ViewChild('nearGoal') nearGoal?: NearGoalComponent;
  // * STEP 4
  @ViewChild('earnedMedia') earnedMedia?: EarnedMediaComponent;

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      console.debug('Query Params:', params);
    });

    this.route.paramMap.subscribe((params) => {
      this.campaignId = Number(params.get('id') || -1);
      this.typeId = Number(params.get('typeId') || -1);
      this.segmentId = Number(params.get('segmentId') || -1);

      if (this.loading) return;

      if (this.segmentId !== -1) {
        this.services = [
          this.api.call(`admin/campaigns/${this.campaignId}`),
          this.api.call(`admin/segments/${this.segmentId}`),
          this.api.call(`admin/catalogs/broadcasts`),
        ];

        // ! DEBUG
        // console.debug('campaignId: ', this.campaignId);
        // console.debug('segmentId: ', this.segmentId);
        // console.debug('typeId: ', this.typeId);
        // console.debug('subtitle: ', this.subtitle);
        // ! DEBUG

        this.endpoint = `admin/segments/${this.segmentId}`;
        this.method = 'put';

        this.loadServices();
      }
    });
  }

  override onLoadServicesSuccess(responses: any[]): void {
    if (responses[0].status === 200) {
      this.campaign.setData(responses[0].data);

      this.title = `${
        this.campaign.clients.length > 0 ? this.campaign.clients[0].name : ''
      } / ${this.campaign.name}`;

      this.goTo(0);
    }

    if (responses[1].status === 200 && responses[1].data) {
      const resData = responses[1].data;
      this.segment = responses[1].data;
      console.debug(this.segment);

      this.type = resData?.type?.property?.name || 'TYPE';

      console.debug(this.type);

      if (resData.broadcasts) {
        let budgets = new Budgets();

        if (this.typeId !== -1) {
          const fBudgets = this.campaign.budgets.filter((item) => {
            return this.typeId === item.id;
          });

          console.debug(this.typeId);
          console.debug(fBudgets);
          console.debug(this.campaign.budgets);

          if (fBudgets && fBudgets.length > 0) {
            budgets = fBudgets[0].budgets;
          }
        }

        if (budgets) {
          this.segmentData.fieldboard.budget = budgets.fieldboards;
          this.segmentData.keyMoments.budget = budgets.key_moments;
          this.segmentData.nearGoal.budget = budgets.near_goal;
        }

        // ! ID 1 -> FIELDBOARDS
        if (resData.broadcasts[0]) {
          this.segmentData.fieldboard.id = resData.broadcasts[0].id;
          this.segmentData.fieldboard.matches = resData.broadcasts[0].items;
          this.segmentData.fieldboard.summary.impressionsContracted = Number(
            resData.broadcasts[0]?.impressions || 0
          );
        }

        // ! ID 2 -> KEY MOMENTS
        if (resData.broadcasts[1]) {
          this.segmentData.keyMoments.id = resData.broadcasts[1].id;
          this.segmentData.keyMoments.matches = resData.broadcasts[1].items;
          this.segmentData.keyMoments.summary.impressionsContracted = Number(
            resData.broadcasts[1]?.impressions || 0
          );
        }

        // ! ID 3 -> NEAR GOAL
        if (resData.broadcasts[2]) {
          this.segmentData.nearGoal.id = resData.broadcasts[2].id;
          this.segmentData.nearGoal.matches = resData.broadcasts[2].items;
          this.segmentData.nearGoal.summary.impressionsContracted = Number(
            resData.broadcasts[2]?.impressions || 0
          );
        }

        // ! ID 4 -> EARNED MEDIA
        if (resData.broadcasts[3]) {
          this.segmentData.earnedMedia.id = resData.broadcasts[3].id;
          this.segmentData.earnedMedia.matches = resData.broadcasts[3].items;
          this.segmentData.earnedMedia.summary.totalViews = Number(
            resData.broadcasts[3]?.impressions || 0
          );
        }
      }

      this.data.data.fieldboard.segments.push(this.segmentData.fieldboard);
      this.data.data.keyMoments.segments.push(this.segmentData.keyMoments);
      this.data.data.nearGoal.segments.push(this.segmentData.nearGoal);
      this.data.data.earnedMedia.segments.push(this.segmentData.earnedMedia);

      this.data.published = resData.published
        ? Boolean(resData.published)
        : false;
      this.data.comments = resData.comments;
      this.stepData.subtitle = `${responses[1].data.name}`;
    }

    // ! DEBUG
    // this.goTo(4);
    // console.debug(this.campaign);
    // console.debug(this.data);
    // console.debug(this.items);
    // ! DEBUG

    this.stopLoading();
  }

  override isValid(): boolean {
    return true;
  }

  override getParams() {
    const params: any = {
      name: this.campaign.name,
      comments: this.data.comments,
      type_id: this.typeId,
      published: this.data.published,
    };

    return params;
  }

  goTo(step: number) {
    this.step = step;
    this.stepData.actions = ['NEW MATCH', 'SAVE'];

    switch (step) {
      case 1:
        this.stepData.subtitle = `${this.type} - Fieldboards`;

        break;

      case 2:
        this.stepData.subtitle = `${this.type} - Key Moments`;

        break;

      case 3:
        this.stepData.subtitle = `${this.type} - Near Goal`;

        break;

      case 4:
        this.stepData.subtitle = `${this.type} - Earned Media`;

        break;

      default:
        this.stepData.actions = ['PUBLISH', 'SAVE'];
        this.stepData.subtitle = this.type;

        break;
    }
  }

  back(): void {
    if (this.step === 0) {
      this.location.back();

      return;
    }

    this.goTo(0);
    this.loadServices();
  }

  handleAction(actionInd: number) {
    switch (this.step) {
      case 1:
        if (this.fieldsboards) {
          if (actionInd === 1) {
            this.fieldsboards.handleSubmit();
          } else {
            this.fieldsboards.newMatch();
          }
        }

        break;

      case 2:
        if (this.keyMoments) {
          if (actionInd === 1) {
            this.keyMoments.handleSubmit();
          } else {
            this.keyMoments.newMatch();
          }
        }

        break;

      case 3:
        if (this.nearGoal) {
          if (actionInd === 1) {
            this.nearGoal.handleSubmit();
          } else {
            this.nearGoal.newMatch();
          }
        }

        break;

      case 4:
        if (this.earnedMedia) {
          if (actionInd === 1) {
            this.earnedMedia.handleSubmit();
          } else {
            this.earnedMedia.newMatch();
          }
        }

        break;

      default:
        this.submit();

        break;
    }
  }

  override success(_response: any): void {
    this.handleAlert(`¡Changes saved successfully!`, 'success');

    this.stopLoading();
  }
}
