import { ReportComponent } from '@admin/components/report/report.component';
import { Component } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';
import { forkJoin } from 'rxjs';

import { AlertComponent, TitleComponent } from '@shared/components';
import { CampaignSeason } from '@admin/models/Playoffs.model';
import { CrudFormComponent } from '@admin/components';
import { Campaign } from '@admin/models/Campaign.model';

@Component({
  selector: 'app-reports-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ReportComponent,
    AlertComponent,
    TitleComponent,
  ],
  templateUrl: './reports-form.component.html',
  styleUrl: './reports-form.component.scss',
})
export class ReportsFormComponent extends CrudFormComponent {
  step: number = 1;
  clients: any[] = [];
  months: any[] = [
    {
      id: 1,
      label: 'Month 1',
    },
    {
      id: 2,
      label: 'Month 2',
    },
    {
      id: 3,
      label: 'Month 3',
    },
  ];

  override data: CampaignSeason = new CampaignSeason();
  campaign: Campaign = new Campaign();
  title: string = '';
  actions: string[] = [];
  subtitle: string = '';

  override form = this.formBuilder.group({
    client: new FormControl<number | null>(null, Validators.required),
    start: new FormControl<number | null>(null, Validators.required),
    end: new FormControl<number | null>(null, Validators.required),
  });

  override initSettings(): void {
    this.services = [
      this.api.call('admin/clients'),
      // this.api.call(`admin/campaigns/${this.dataId()}`),
    ];

    // ! DEBUG
    // this.step = 2;
    // ! DEBUG
  }

  // override getFormatEndpoint(): string {
  //   console.debug(this.typeId);

  //   return `admin/campaigns/${this.dataId()}/segments?limit=10&page=1&type_id=${
  //     this.typeId
  //   }`;
  // }

  override handleLoadResponse(responses: any[]): void {
    if (responses[0].status === 200) {
      this.clients = responses[0].data.rows;
    }

    if (responses[1] && responses[1].status === 200) {
      this.campaign = responses[1].data;

      this.title = `${
        this.campaign.clients.length > 0 ? this.campaign.clients[1].name : ''
      } / ${this.campaign.name}`;

      this.subtitle = 'Playoffs';
      this.actions = ['PUBLISH'];
    }
  }

  generate() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.handleAlert('Fill all required fields.');

      return;
    }

    this.step = 2;
  }

  next() {
    this.step += 1;
  }
}
