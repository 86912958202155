<div [ngClass]="isComponent ? 'w-100' : 'container'">
  <app-crud-form-header
    [id]="dataId()"
    [disable]="readonly"
    [modal]="isComponent"
    title="property"
  ></app-crud-form-header>
</div>

<div [ngClass]="isComponent ? 'modal-body' : 'container py-3 py-lg-4'">
  <form
    [formGroup]="data"
    (ngSubmit)="submit()"
    class="row justify-content-around gy-3 gy-lg-4"
  >
    <div class="col-12">
      <label for="name" class="form-label">Name</label>
      <input
        id="name"
        name="name"
        class="form-control"
        type="text"
        formControlName="name"
        [ngClass]="{
          'is-invalid':
            data.get('name')?.invalid &&
            (data.get('name')?.dirty || data.get('name')?.touched)
        }"
      />

      @if (data.get('name')?.invalid && (data.get('name')?.dirty ||
      data.get('name')?.touched) ) {
      <div id="validationName" class="invalid-feedback">Name is required.</div>
      }
    </div>

    <div class="w-100"></div>

    <div class="col-12">
      <p class="fw-bold mb-1">Property</p>

      <p class="mb-2">Assigns to an existing property.</p>

      <ng-select
        id="tag"
        appearance="outline"
        formControlName="property_id"
        placeholder="Select a property"
        [clearable]="false"
        [searchable]="false"
      >
        @for (item of properties; track item.id) {
        <ng-option [value]="item.id">{{ item.name }}</ng-option>
        }
      </ng-select>

      @if ( data.get('property_id')?.invalid && (data.get('property_id')?.dirty
      || data.get('property_id')?.touched)) {
      <div id="tagValidation" class="text-danger">Property is required</div>
      }
    </div>

    <app-alert [content]="alertData"></app-alert>

    <div class="w-100 mt-0"></div>

    @if (!isComponent) {
    <div class="col-12 px-0">
      <app-crud-form-footer
        [created]="created"
        [disable]="readonly"
        [modal]="isComponent"
        [loading]="loadingService.getLoading()"
        (cancel)="cancel()"
      ></app-crud-form-footer>
    </div>
    }
  </form>
</div>

@if (isComponent) {
<app-crud-form-footer
  [created]="created"
  [disable]="readonly"
  [modal]="isComponent"
  [loading]="loadingService.getLoading()"
  (onSubmit)="submit()"
  (cancel)="cancel()"
></app-crud-form-footer>
}
