import { MatchModel } from './Match.model';

export class Fieldboard {
  id: number = -1;
  budget: number = 0;
  description: string = '';
  summary = {
    unitsContracted: 0,
    unitsDelivered: 0,
    impressionsContracted: 0,
    impressionsDelivered: 0,
  };
  info = {
    totals: 0,
    contracted: 0,
    delivered: 0,
    variance: 0,
  };
  matches: MatchModel[] = [];

  setData(data: any) {
    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }
    }

    this.summary.unitsContracted = Number(this.summary.unitsContracted);
    this.summary.unitsDelivered = Number(this.summary.unitsDelivered);
    this.summary.impressionsContracted = Number(
      this.summary.impressionsContracted
    );
    this.summary.impressionsDelivered = Number(
      this.summary.impressionsDelivered
    );

    this.info.totals = Number(this.info.totals);
    this.info.contracted = Number(this.info.contracted);
    this.info.delivered = Number(this.info.delivered);
    this.info.variance = Number(this.info.variance);
  }

  init() {
    this.setData(this);
    console.debug(this);
    
    this.calculateSummary();
    this.calculateCPMContrated();
    this.calculateCPMDelivered();
    this.calculateVariance();
  }

  calculateSummary() {
    const [unitsContracted, unitsDelivered, impressionsDelivered] =
      this.matches.reduce(
        (acc, match) => {
          return [
            acc[0] + match.unitsContracted,
            acc[1] + match.unitsDelivered,
            acc[2] + match.impressions,
          ];
        },
        [0, 0, 0]
      );

    this.summary.unitsContracted = Number(unitsContracted || 0);
    this.summary.unitsDelivered = Number(unitsDelivered || 0);
    this.summary.impressionsDelivered = Number(impressionsDelivered || 0);

    console.groupEnd();
  }

  // Presupuesto ÷ Impresiones Contrated × 1,000 = CPM
  calculateCPMContrated() {
    if (this.summary.impressionsContracted < 1) {
      return;
    }

    this.info.contracted =
      (this.budget / this.summary.impressionsContracted) * 1000;

    this.calculateVariance();
  }

  // Presupuesto ÷ Impresiones Delivered × 1,000 = CPM
  calculateCPMDelivered() {
    if (this.summary.impressionsDelivered < 1) {
      console.error('no valid delivered: ', this.summary);

      return;
    }

    this.info.delivered = Number(
      (this.budget / this.summary.impressionsDelivered) * 1000
    );

    this.calculateVariance();
  }

  calculateVariance() {
    if (this.info.contracted > 0 && this.info.delivered > 1) {
      this.info.variance = Number(this.info.contracted - this.info.delivered);
    }
  }
}
