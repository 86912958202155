<div class="row">
  <div class="col-12">
    <div class="w-100 table-responsive card mb-4">
      <table class="table text-center mb-0">
        <thead class="table-secondary">
          <tr>
            <th scope="col">Total Views</th>
          </tr>
        </thead>

        <tbody class="table-warning">
          <tr>
            <td class="col-3">{{ data.summary.totalViews | number }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  @for (item of data.matches; track $index) {
  <div id="match-{{ $index + 1 }}" class="col-12 mb-4">
    <app-earned-media-video
      [index]="$index"
      [data]="item"
      (onChange)="calculateTotal()"
      (onMedia)="handleFileMedia($event, $index)"
      (onDelete)="deleteMatch($index)"
    ></app-earned-media-video>
  </div>
  }
</div>
