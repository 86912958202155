import { ActivatedRoute } from '@angular/router';
import { Component, inject } from '@angular/core';
import { CurrencyPipe, DecimalPipe, Location } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { forkJoin } from 'rxjs';

import { Broadcast } from '@admin/models/Broadcast.model';
import { Budgets } from '@admin/models/Campaign.model';
import { CampaignReport } from '@admin/models/CampaignReport.model';
import { CampaignSeason } from '@admin/models/Playoffs.model';
import { ReportComponent } from '@admin/components/report/report.component';
import { Segment } from '@admin/models/Segment.model';
import { FormComponent, TitleComponent } from '@shared/components';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ReportType } from '@admin/enums/ReportType.enum';

@Component({
  selector: 'app-general-report',
  standalone: true,
  imports: [
    FormsModule,
    DecimalPipe,
    CurrencyPipe,
    ReportComponent,
    TitleComponent,
  ],
  providers: [DecimalPipe, CurrencyPipe],
  templateUrl: './general-report.component.html',
  styleUrl: './general-report.component.scss',
})
export class GeneralReportComponent extends FormComponent {
  private currencyPipe = inject(CurrencyPipe);
  private decimalPipe = inject(DecimalPipe);
  private location = inject(Location);
  private route = inject(ActivatedRoute);

  override data: CampaignReport = new CampaignReport();

  title: string = 'CLIENT NAME / CAMPAIGN NAME';
  campaignId: number = -1;
  comments = {
    general: '',
    fieldboards: '',
    'key-moments': '',
    'near-goal': '',
    'earned-media': '',
    playoffs: '',
  };
  reportType: ReportType = ReportType.general;
  types: string[] = [];
  typeId: number = -1;
  periodId: number = -1;
  segmentsSer: string[] = [];
  segments: Segment[] = [];
  broadcasts: Broadcast[] = [];

  ngOnInit(): void {
    const data: any = this.route.snapshot.data;

    if (data.type) {
      this.reportType = data.type;
    }

    this.route.paramMap.subscribe((params) => {
      if (this.campaignId === -1) {
        this.campaignId = Number(params.get('id') ?? -1);
      }

      this.typeId = Number(params.get('typeId') ?? -1);
      this.periodId = Number(params.get('period') ?? -1);

      if (this.loading) return;

      this.services = [
        this.api.call(`admin/campaigns/${this.campaignId}`),
        this.api.call(`admin/catalogs/broadcasts`),
        this.api.call(
          `admin/reports/${
            this.typeId === -1
              ? 'campaign/' + this.campaignId
              : 'type/' + this.typeId
          }`
        ),
      ];

      this.loadServices();
    });
  }

  override onLoadServicesSuccess(responses: any[]): void {
    // ! CAMPAIGN RESPONSE
    if (responses[0].status === 200) {
      this.data.campaign.setData(responses[0].data);

      this.title = `${
        this.data.campaign.clients.length > 0
          ? this.data.campaign.clients[0].name
          : ''
      } / ${this.data.campaign.name}`;
    }

    // ! TYPES INDEX
    if (responses[1] && responses[1].status === 200) {
      this.broadcasts = responses[1].data;
    }

    if (responses[2] && responses[2].status === 200) {
      console.debug('RESPONSE: ', responses[2].data);

      const playoffs = this.getPlayoffs(responses[2].data);
      console.debug(playoffs);

      this.data.segments = playoffs;
    }

    console.debug(this.data);
    console.debug(this.broadcasts);

    console.debug(this.initialized);

    this.stopLoading();
  }

  loadTypes() {
    if (this.types.length === 0) {
      this.stopLoading();
      return;
    }

    const services = this.types.map((segment) => {
      return this.api.call(segment);
    });

    this.segmentsSer = [];

    forkJoin(services).subscribe({
      next: (responses) => {
        responses.forEach((response) => {
          if (response.status === 200 && response.data.rows) {
            response.data.rows.forEach((row: any) => {
              this.segmentsSer.push(`admin/segments/${row.id}`);
            });
          }
        });

        console.debug(this.segmentsSer);
      },
      error: (errors) => {
        console.error(errors);

        this.stopLoading();
      },
    });
  }

  getPlayoffs(segmentsData: any) {
    const season = new CampaignSeason();

    let budgets = new Budgets();

    if (this.typeId !== -1) {
      const fBudgets = this.data.campaign.budgets.filter((item) => {
        return this.typeId === item.id;
      });

      console.debug('typeId: ' + this.typeId);
      console.debug('Budgets arr: ', this.data.campaign.budgets);
      console.debug('Budgets: ', fBudgets);

      if (fBudgets && fBudgets.length > 0) {
        budgets = fBudgets[0].budgets;
      }
    }

    console.debug(segmentsData);

    if (segmentsData) {
      // ! ID 1 - Fieldboards
      if (segmentsData[0]) {
        season.data.fieldboard.setData(segmentsData[0]);
        season.data.fieldboard.budget = budgets.fieldboards;
      }

      // ! ID 2 - Key Moments
      if (segmentsData[1]) {
        season.data.keyMoments.setData(segmentsData[1]);
        season.data.keyMoments.budget = budgets.key_moments;
      }

      // ! ID 3 - Near Goal
      if (segmentsData[2]) {
        season.data.nearGoal.setData(segmentsData[2]);
        season.data.nearGoal.budget = budgets.near_goal;
      }

      // ! ID 4
      if (segmentsData[3]) {
        season.data.earnedMedia.setData(segmentsData[3]);
      }
    }

    season.calculate();
    console.debug(season);

    return season;
  }

  handleAction(action: number): void {
    switch (action) {
      case 0:
        this.downloadAsPDF();
        // const report = document.getElementById('report');

        // if (report) {
        //   html2canvas(report, {
        //     scale: 2,
        //     useCORS: true,
        //     logging: true,
        //   }).then((canvas) => {
        //     const image = canvas.toDataURL('image/jpeg', 1.0);

        //     const pdf = new jsPDF();

        //     const pageWidth = pdf.internal.pageSize.getWidth();
        //     const pageHeight = pdf.internal.pageSize.getHeight();
        //     const imgWidth = canvas.width;
        //     const imgHeight = canvas.height;

        //     const ratio = Math.min(
        //       pageWidth / imgWidth,
        //       pageHeight / imgHeight
        //     );

        //     const imgScaledWidth = imgWidth * ratio;
        //     const imgScaledHeight = imgHeight * ratio;

        //     pdf.addImage(image, 'PNG', 0, 0, imgScaledWidth, imgScaledHeight);

        //     // Descargar el PDF
        //     pdf.save('documento.pdf');
        //   });
        // }

        break;

      case 1:
        this.endpoint = `admin/campaigns/${this.campaignId}`;
        this.method = 'put';

        this.submit();
        break;

      default:
        break;
    }
  }

  downloadAsPDF() {
    const element = document.getElementById('report');

    if (!element) {
      return;
    }

    // Configuración del tamaño A4
    const pdf = new jsPDF('p', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();

    html2canvas(element, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = pageWidth;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      let y = 0;

      while (y < imgHeight) {
        pdf.addImage(imgData, 'PNG', 0, -y, imgWidth, imgHeight);
        y += pageHeight;

        // Añadir nueva página si hay contenido sobrante
        if (y < imgHeight) {
          pdf.addPage();
        }
      }

      // Descargar el archivo PDF
      pdf.save('documento.pdf');
    });
  }

  override getParams() {
    const { clients, ...params } = this.data.campaign;

    const data: any = {
      ...params,
    };

    if (clients) {
      data['clients'] = clients.map((type: any) => type.id);
    }

    return data;
  }

  override success(_response: any): void {
    this.handleAlert(`¡Changes saved successfully!`, 'success');

    this.stopLoading();
  }

  formatNumber(value: number): string {
    return this.decimalPipe.transform(value, '1.1-2') || '';
  }

  formatCurrency(value: number, currencyCode: string): string {
    return (
      this.currencyPipe.transform(value, currencyCode, 'symbol', '1.2-2') || ''
    );
  }

  back(): void {
    this.location.back();
  }
}
