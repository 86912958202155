import { Component, input } from '@angular/core';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { TableReport } from '@admin/models/TableReport.model';
import { CampaignReport } from '@admin/models/CampaignReport.model';
import { Broadcast } from '@admin/models/Broadcast.model';
import { Budget } from '@admin/models/Campaign.model';

@Component({
  selector: 'app-report',
  standalone: true,
  imports: [FormsModule, CurrencyPipe, DecimalPipe],
  templateUrl: './report.component.html',
  styleUrl: './report.component.scss',
})
export class ReportComponent {
  budget = new Budget();
  data = input<CampaignReport>(new CampaignReport());
  readonly = input<boolean>(true);
  typeId = input<number>(-1);
  sections: TableReport[] = [];
  summary = {
    units: {
      contracted: 0,
      delivered: 0,
      variance: 0,
    },
    impressions: {
      contracted: 0,
      delivered: 0,
      variance: 0,
    },
    cpm: {
      contracted: 0,
      delivered: 0,
      variance: 0,
    },
    description: '',
  };

  ngOnInit(): void {
    console.debug(this.data());

    if (this.typeId() !== -1) {
      const fBudgets = this.data().campaign.budgets.filter((item) => {
        return this.typeId() === item.id;
      });

      console.debug(this.typeId);
      console.debug(fBudgets);
      console.debug(this.data().campaign.budgets);

      if (fBudgets && fBudgets.length > 0) {
        this.budget = fBudgets[0];
      }
    }

    this.initTables();
  }

  initTables() {
    this.calculateSummary();

    const fieldboard = this.calculateFieldboards();
    this.sections.push(fieldboard);

    const keyMoments = this.calculateKeyMoments();
    this.sections.push(keyMoments);

    const nearGoal = this.calculateNearGoal();
    this.sections.push(nearGoal);

    const earnedMedia = this.calculateEarnedMedia();
    this.sections.push(earnedMedia);

    // console.debug(this.sections);
    // console.debug(this.summary);
  }

  calculateSummary() {
    const [
      fieldboardUC,
      fieldboardUD,
      fieldboardID,
      fieldboardIC,
      fieldboardC,
      fieldboardD,
    ] = this.data().segments.data.fieldboard.segments.reduce(
      (acc, segment) => {
        return [
          acc[0] + segment.summary.unitsContracted,
          acc[1] + segment.summary.unitsDelivered,
          acc[2] + segment.summary.impressionsDelivered,
          acc[3] + segment.summary.impressionsContracted,
          acc[4] + segment.info.contracted,
          acc[5] + segment.info.delivered,
        ];
      },
      [0, 0, 0, 0, 0, 0]
    );
    console.group('calculateFielboard');

    console.debug('fieldboardUC: ' + fieldboardUC);
    console.debug('fieldboardUD: ' + fieldboardUD);
    console.debug('fieldboardID: ' + fieldboardID);
    console.debug('fieldboardIC: ' + fieldboardIC);
    console.debug('fieldboardC: ' + fieldboardC);
    console.debug('fieldboardD: ' + fieldboardD);

    console.groupEnd();

    const [
      keyMomentsUC,
      keyMomentsUD,
      keyMomentsID,
      keyMomentsIC,
      keyMomentsC,
      keyMomentsD,
    ] = this.data().segments.data.keyMoments.segments.reduce(
      (acc, segment) => {
        return [
          acc[0] + segment.summary.unitsContracted,
          acc[1] + segment.summary.unitsDelivered,
          acc[2] + segment.summary.impressionsDelivered,
          acc[3] + segment.summary.impressionsContracted,
          acc[4] + segment.info.contracted,
          acc[5] + segment.info.delivered,
        ];
      },
      [0, 0, 0, 0, 0, 0]
    );

    console.group('calculateFielboard');

    console.debug('keyMomentsUC: ' + keyMomentsUC);
    console.debug('keyMomentsUD: ' + keyMomentsUD);
    console.debug('keyMomentsID: ' + keyMomentsID);
    console.debug('keyMomentsIC: ' + keyMomentsIC);
    console.debug('keyMomentsC: ' + keyMomentsC);
    console.debug('keyMomentsD: ' + keyMomentsD);

    console.groupEnd();

    const [nearGoalUR, nearGoalIC, nearGoalID] =
      this.data().segments.data.nearGoal.segments.reduce(
        (acc, segment) => {
          return [
            acc[0] + segment.summary.unitsReceived,
            acc[1] + segment.info.contracted,
            acc[2] + segment.info.delivered,
          ];
        },
        [0, 0, 0]
      );

    const totalViews = this.data().segments.data.earnedMedia.segments.reduce(
      (acc, segment) => {
        return acc + segment.summary.totalViews;
      },
      0
    );
    console.group('calculateNearGoal');

    console.debug('nearGoalUR: ' + nearGoalUR);
    console.debug('nearGoalIC: ' + nearGoalIC);
    console.debug('nearGoalID: ' + nearGoalID);

    console.groupEnd();

    console.group('Summary');

    console.debug(
      'season.impressionsContracted :' +
        this.data().segments.impressionsContracted
    );
    console.debug(
      'season.impressionsDelivered :' +
        this.data().segments.impressionsDelivered
    );
    console.debug(
      'season.unitsContracted :' + this.data().segments.unitsContracted
    );
    console.debug(
      'season.unitsDelivere :' + this.data().segments.unitsDelivered
    );

    // * UNITS
    this.summary.units.contracted = fieldboardUC + keyMomentsUC + nearGoalUR;
    this.summary.units.delivered = fieldboardUD + keyMomentsUD;
    this.summary.units.variance =
      this.summary.units.delivered - this.summary.units.contracted;
    // * UNITS

    // * IMPRESSIONS
    this.summary.impressions.contracted =
      fieldboardIC + keyMomentsIC + nearGoalIC;
    this.summary.impressions.delivered =
      fieldboardID + keyMomentsID + nearGoalID + totalViews;
    this.summary.impressions.variance =
      this.summary.impressions.delivered - this.summary.impressions.contracted;
    // * IMPRESSIONS

    // * CPM
    if (this.data().campaign) {
      this.summary.cpm.contracted =
        this.summary.impressions.contracted > 0
          ? (this.data().campaign.budget /
              this.summary.impressions.contracted) *
            1000
          : 0;
      this.summary.cpm.contracted =
        this.summary.impressions.delivered > 0
          ? (this.data().campaign.budget / this.summary.impressions.delivered) *
            1000
          : 0;
      this.summary.cpm.variance =
        this.summary.cpm.delivered - this.summary.cpm.contracted;
    }
    // * CPM
    console.debug(this.summary);

    console.groupEnd();
  }

  calculateFieldboards(): TableReport {
    const nTable = new TableReport();
    nTable.comentControl = 'fieldboards';

    const fieldboardsBudget = this.budget.budgets.fieldboards || 0;

    const [
      unitsContracted,
      unitsDelivered,
      impressionsDelivered,
      impressionsContracted,
    ] = [
      this.data().segments.unitsContracted,
      this.data().segments.unitsDelivered,
      this.data().segments.impressionsDelivered,
      this.data().segments.impressionsContracted,
    ];

    nTable.title = `FIELDBOARDS`;
    nTable.cols = ['Totals', 'Contracted', 'Delivered', 'Variance'];
    nTable.rows = [
      [
        'Units',
        unitsContracted,
        unitsDelivered,
        unitsContracted - unitsDelivered,
      ],
      [
        'Impressions',
        impressionsContracted,
        impressionsDelivered,
        impressionsDelivered - impressionsContracted,
      ],
      [
        'CPM',
        impressionsContracted > 0
          ? (fieldboardsBudget / impressionsContracted) * 1000
          : 0,
        impressionsDelivered > 0
          ? (fieldboardsBudget / impressionsDelivered) * 1000
          : 0,
        (impressionsContracted > 0
          ? (fieldboardsBudget / impressionsContracted) * 1000
          : 0) -
          (impressionsDelivered > 0
            ? (fieldboardsBudget / impressionsDelivered) * 1000
            : 0),
      ],
    ];

    return nTable;
  }

  calculateKeyMoments(): TableReport {
    const nTable = new TableReport();
    nTable.comentControl = 'key-moments';

    const [
      keyMomentsUC,
      keyMomentsUD,
      keyMomentsID,
      keyMomentsIC,
      keyMomentsC,
      keyMomentsD,
      keyMomentsV,
    ] = this.data().segments.data.keyMoments.segments.reduce(
      (acc, segment) => {
        return [
          acc[0] + segment.summary.unitsContracted,
          acc[1] + segment.summary.unitsDelivered,
          acc[2] + segment.summary.impressionsDelivered,
          acc[3] + segment.summary.impressionsContracted,
          acc[4] + segment.info.contracted,
          acc[5] + segment.info.delivered,
          acc[6] + segment.info.variance,
        ];
      },
      [0, 0, 0, 0, 0, 0, 0]
    );

    nTable.title = `KEY MOMENTS`;
    nTable.cols = ['Totals', 'Contracted', 'Delivered', 'Variance'];
    nTable.rows = [
      ['Units', keyMomentsUC, keyMomentsUD, keyMomentsUD - keyMomentsUC],
      ['Impressions', keyMomentsIC, keyMomentsID, keyMomentsID - keyMomentsIC],
      ['CPM', keyMomentsC, keyMomentsD, keyMomentsV],
    ];

    return nTable;
  }

  calculateNearGoal(): TableReport {
    const nTable = new TableReport();
    nTable.comentControl = 'near-goal';

    const [
      nearGoalID,
      nearGoalIC,
      nearGoalUR,
      nearGoalInfoC,
      nearGoalInfoD,
      nearGoalInfoV,
    ] = this.data().segments.data.nearGoal.segments.reduce(
      (acc, segment) => {
        return [
          acc[0] + segment.summary.impressionsDelivered,
          acc[1] + segment.summary.impressionsContracted,
          acc[2] + segment.summary.unitsReceived,
          acc[3] + segment.info.contracted,
          acc[4] + segment.info.delivered,
          acc[5] + segment.info.variance,
        ];
      },
      [0, 0, 0, 0, 0, 0]
    );

    nTable.title = `NEAR GOAL`;
    nTable.cols = ['Totals', 'Contracted', 'Delivered', 'Variance'];
    nTable.rows = [
      ['Units', nearGoalUR, '-', '-'],
      ['Impressions', nearGoalIC, nearGoalID, nearGoalID - nearGoalIC],
      ['CPM', nearGoalInfoC, nearGoalInfoD, nearGoalInfoV],
    ];

    return nTable;
  }

  calculateEarnedMedia(): TableReport {
    const nTable = new TableReport();
    nTable.comentControl = 'earned-media';

    const totalViews = this.data().segments.data.earnedMedia.segments.reduce(
      (acc, segment) => {
        return acc + segment.summary.totalViews;
      },
      0
    );

    nTable.title = `EARNED MEDIA`;
    nTable.cols = ['Totals', 'Delivered'];
    nTable.rows = [['Impressions', totalViews]];

    return nTable;
  }

  calculatePlayoffs(): TableReport {
    const playoffsBudget =
      this.budget.budgets.fieldboards +
        this.budget.budgets.key_moments +
        this.budget.budgets.near_goal || 0;

    const nTable = new TableReport();
    nTable.comentControl = 'playoffs';

    const [
      unitsContracted,
      unitsDelivered,
      impressionsDelivered,
      impressionsContracted,
    ] = [
      this.data().segments.unitsContracted,
      this.data().segments.unitsDelivered,
      this.data().segments.impressionsDelivered,
      this.data().segments.impressionsContracted,
    ];

    nTable.title = `PLAYOFFS`;
    nTable.cols = ['Totals', 'Contracted', 'Delivered', 'Variance'];
    nTable.rows = [
      [
        'Units',
        unitsContracted,
        unitsDelivered,
        unitsDelivered - unitsContracted,
      ],
      [
        'Impressions',
        impressionsContracted,
        impressionsDelivered,
        impressionsDelivered - impressionsContracted,
      ],
      [
        'CPM',
        impressionsContracted > 0
          ? (playoffsBudget / impressionsContracted) * 1000
          : 0,
        impressionsDelivered > 0
          ? (playoffsBudget / impressionsDelivered) * 1000
          : 0,
        (impressionsContracted > 0
          ? (playoffsBudget / impressionsContracted) * 1000
          : 0) -
          (impressionsDelivered > 0
            ? (playoffsBudget / impressionsDelivered) * 1000
            : 0),
      ],
    ];

    return nTable;
  }
}
