import { Main } from '@shared/models/Main.model';
import { Segment } from './Segment.model';

export class CampaignType extends Main {
  id: number = -1;
  campaign_id: number = -1;
  comments: Comments = new Comments();
  property: Property = new Property();
  budgets: Budgets = new Budgets();
  type: Type | null = null;
  types: Type[] = [];
  segments: Segment[] = [];
}

export class Type {
  id: number = -1;
  comments: Comments = new Comments();
  property: Property = new Property();
  type: Type | null = null;
  types: Type[] = [];
  segments: Segment[] = [];
}

export class Comments {
  fieldboards: string = '';
  key_moments: string = '';
  near_goal: string = '';
  earned_media: string = '';
}

export class Property {
  id: number = -1;
  name: string = '';
  property: Property | null = null;
}

export class Budgets {
  fieldboards: number = 0;
  key_moments: number = 0;
  near_goal: number = 0;
}
