export class Segment {
  id: number = -1;
  name: string = '';
  comments: string | null = null;
  published: number = 0;
  broadcasts: SegmentBroadcast[] = [];
  type: SegmentType = new SegmentType();
}

export class SegmentBroadcast {
  id: number = -1;
  impressions: number = 0;
  items: any[] = [];
}

export class SegmentType {
  id: number = -1;
  campaign_id: number = -1;
  comments: Comments = new Comments();
  property: Property = new Property();
  type: TypeType = new TypeType();
}

export class Comments {
  fieldboards: string = '';
  key_moments: string = '';
  near_goal: string = '';
  earned_media: string = '';
}

export class Property {
  id: number = -1;
  name: string = '';
  property: Property | null = null;
}

export class TypeType {
  id: number = -1;
  comments: Comments = new Comments();
  property: Property = new Property();
  type: string | null = null;
}
