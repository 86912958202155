@if (breadcrumbs().length > 0) {
<nav aria-label="breadcrumb" class="container-fluid pt-4 mb-0">
  <ol class="breadcrumb mb-0">
    @for (item of breadcrumbs(); track $index) {
    <li class="breadcrumb-item" [ngClass]="{ active: !item.url }" @fadeIn>
      @if (item.url) {
      <a [routerLink]="[item.url]">{{ item.label }}</a>
      } @else {
      <span class="fw-normal">
        {{ item.label }}
      </span>
      }
    </li>
    }
  </ol>
</nav>
}
