<app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>

<app-title
  [title]="title"
  [subtitle]="subtitle"
  [description]="data.description"
>
  <div class="row py-3">
    <div class="col-auto ms-auto">
      <button type="button" class="btn btn-secondary px-4" (click)="goBack()">
        <i class="fa-solid fa-chevron-left"></i>
      </button>
    </div>
  </div>

  <!-- ! DEBUG -->
  <!-- <div class="row">
    <div class="col-12 px-0">
      <span class="badge text-bg-warning">
        edit: {{ editing }} // endpoint: {{ endpoint }} // method:
        {{ method }} // typeId: {{ typeId }} // creating: {{ creating }} //
        loading: {{ loading }} // page:
        {{ page }}
      </span>
    </div>
  </div> -->
  <!-- ! DEBUG -->
</app-title>

<div class="container py-3 py-lg-4 py-xl-5">
  <div
    class="row gy-3 gy-md-4"
    [ngClass]="{ 'card-height': editing !== -1 || creating }"
  >
    <div class="col-md-6 col-xl-4 py-3 py-lg-4 border-md-end border-primary">
      <app-main-card
        icon="fa-solid fa-file-lines"
        (onPrimary)="generalReport()"
        primary="arrow_forward_ios"
        secondary=""
      >
        <div class="row gx-2">
          <div class="col-12 text-center pb-2">
            <h5 class="mb-2">Add / View</h5>

            <h5 class="fw-bold mb-0 text-primary">
              {{ typeId !== -1 ? 'Quarter Report' : 'Campaign Report' }}
            </h5>
          </div>
        </div>
      </app-main-card>
    </div>

    @if (canCreate()) {
    <div class="col-md-6 col-xl-4 my-auto" @fadeIn>
      @if (creating) {
      <app-main-card
        [hasImage]="false"
        primary="save"
        secondary="close"
        (onSecondary)="cancelNew()"
        (onPrimary)="new.submit()"
      >
        <app-type-form
          #new
          [campaignId]="campaignId"
          [creating]="true"
          [properties]="properties"
          [typeId]="typeId"
          (onSubmit)="handleSubmit()"
        ></app-type-form>
      </app-main-card>
      } @else {
      <button
        class="btn btn-primary w-100 h-100 card-new"
        data-aos="flip-left"
        (click)="createType()"
      >
        <div class="d-flex w-100 h-100">
          <h1 class="m-auto"><i class="fa-solid fa-plus"></i></h1>
        </div>
      </button>
      }
    </div>
    }
    <!--  -->
    @for (item of items; track $index) {
    <div class="col-md-6 col-xl-4" @fadeIn>
      <app-main-card
        icon="fa-solid fa-calendar-days"
        [primary]="
          canUpdate()
            ? editing === item.id
              ? 'save'
              : 'arrow_forward_ios'
            : ''
        "
        [secondary]="canEdit() ? (editing === item.id ? 'close' : 'edit') : ''"
        [extra]="canDelete() ? 'delete' : ''"
        (onPrimary)="handlePrymary(item)"
        (onSecondary)="editType(item.id)"
        (onExtra)="deleteModal(item.id)"
      >
        @if (item.id === editing) {
        <app-type-form
          #edit
          [campaignId]="campaignId"
          [creating]="false"
          [data]="item"
          [properties]="properties"
          [typeId]="typeId"
          (onSubmit)="handleSubmit()"
        ></app-type-form>
        } @else {
        <div class="row gx-2">
          <div class="col-12 text-center pb-2" @fadeIn>
            <h5 class="mb-2">Add / View</h5>

            <h5 class="fw-bold mb-0 text-primary">{{ item.property.name }}</h5>
          </div>
        </div>
        }
      </app-main-card>
    </div>
    }
    <!--  -->
    @if (items.length > limit) {
    <div class="col-12 text-center" @fadeIn>
      <button type="button" class="btn btn-primary" (click)="loadMore()">
        Load more
      </button>
    </div>
    }
  </div>
</div>
