import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';

import { CampaignTimeControllerComponent } from '@admin/components/campaign-time-controller/campaign-time-controller.component';
import { Fieldboard } from '@admin/models/Fieldboard.model';
import { MatchModel } from '@admin/models/Match.model';
import { ACTION_LOADING } from '@admin/campaigns/constants/variables';

import { FormComponent } from '@shared/components';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'app-fieldboards',
  standalone: true,
  imports: [FormsModule, CurrencyPipe, CampaignTimeControllerComponent],
  templateUrl: './fieldboards.component.html',
  styleUrl: './fieldboards.component.scss',
})
export class FieldboardsComponent extends FormComponent {
  @Input() override data: Fieldboard = new Fieldboard();
  @Input() typeId: number = -1;

  ngOnInit(): void {
    console.debug(this.typeId);
    console.debug(this.data);

    this.method = 'put';
    this.endpoint = `admin/segments/broadcast/${this.data.id}`;

    if (this.data.matches.length === 0) {
      this.newMatch();
    } else {
      this.data.matches = this.data.matches.map((match) => {
        return new MatchModel(match);
      });
    }

    this.data.init();
    console.debug(this.data);

    this.calculateCPMContrated();
    this.calculateTotal();
  }

  handleSubmit() {
    console.log(this.files);

    if (this.files.length > 0) {
      this.handleMedia().subscribe({
        next: (paths: string[]) => {
          let pIndex = 0;
          this.files.forEach((mathFile, index) => {
            mathFile.file.forEach((file, findex) => {
              if (file instanceof File && paths[pIndex]) {
                this.data.matches[index].media[findex] = paths[pIndex];
                pIndex++;
              }
            });
          });

          this.submit();
        },
        error: (error) => {
          this.handleAlert(error || 'An error occurred');
          this.stopLoading();
        },
      });
    } else {
      this.submit();
    }
  }

  newMatch() {
    const nMatch = new MatchModel();

    this.data.matches.push(nMatch);

    setTimeout(() => {
      const element = document.getElementById(
        `match-${this.data.matches.length}`
      );

      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, ACTION_LOADING - 80);
  }

  deleteMatch(index: number) {
    this.data.matches.splice(index, 1);
  }

  override isValid(): boolean {
    const valid = this.data.matches.length > 0;

    if (!valid) {
      this.handleAlert('Match is required');
    }

    return valid;
  }

  override getParams() {
    const params = {
      impressions: this.data.summary.impressionsContracted,
      items: this.data.matches,
    };

    return params;
  }

  override success(_response: any): void {
    this.handleAlert(`¡Changes saved successfully!`, 'success');

    this.stopLoading();
  }

  calculateCPMContrated() {
    this.data.calculateCPMContrated();
  }

  calculateTotal() {
    this.data.calculateSummary();
    this.data.calculateCPMDelivered();
  }
}
