import { Component, inject } from '@angular/core';
import { NgClass } from '@angular/common';
import {
  Validators,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  FormArray,
  FormGroup,
} from '@angular/forms';
import { RouterLink } from '@angular/router';

import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { Observable } from 'rxjs';

import {
  CrudFormComponent,
  CrudFormFooterComponent,
  CrudFormHeaderComponent,
  ImageControllerComponent,
} from '@admin/components';
import { CrudElement } from '@admin/models/CrudElement.model';
import { AlertComponent } from '@shared/components/alert/alert.component';
import { SimpleElement } from '@admin/models/SimpleElement.model';
import { ClientsFormComponent } from '@admin/pages';

@Component({
  selector: 'app-campaigns-form',
  standalone: true,
  imports: [
    NgClass,
    RouterLink,
    FormsModule,
    ReactiveFormsModule,

    NgSelectModule,
    MatInputModule,
    MatRadioModule,

    AlertComponent,
    CrudFormHeaderComponent,
    CrudFormFooterComponent,
    ImageControllerComponent,
  ],
  templateUrl: './campaigns-form.component.html',
  styleUrl: './campaigns-form.component.scss',
})
export class CampaignsFormComponent extends CrudFormComponent {
  protected modalService = inject(NgbModal);

  broadcasts: CrudElement[] = [];
  clients: CrudElement[] = [];
  properties: SimpleElement[] = [];

  override services: Observable<any>[] = [
    this.api.call('admin/clients?limit=1000'),
    this.api.call('admin/catalogs/properties'),
  ];
  override data = this.formBuilder.group({
    budgets: this.formBuilder.array([]),
    name: new FormControl<string | null>(null, Validators.required),
    description: ['', [Validators.required]],
    clients: new FormControl<number[] | null>(null, Validators.required),
    budget: [0],
  });

  openNewClient() {
    const modalRef = this.modalService.open(ClientsFormComponent, {
      size: 'lg',
      scrollable: true,
    });

    modalRef.componentInstance.endpoint = 'admin/clients';
    modalRef.componentInstance.isComponent = true;

    modalRef.componentInstance.onAction.subscribe((result: boolean) => {
      modalRef.close();

      if (result) {
        this.load();
      }
    });
  }

  override handleLoadResponse(responses: any[]): void {
    if (responses[0] && responses[0].status === 200)
      this.clients = responses[0].data.rows;

    if (responses[1] && responses[1].status === 200)
      this.properties = responses[1].data;

    if (
      this.dataId() !== -1 &&
      responses[responses.length - 1] &&
      responses[responses.length - 1].status === 200
    ) {
      const responseData = responses[responses.length - 1].data;
      this.setParams(responseData);
    }
  }

  override onSuccess(response: any): void {
    if (this.dataId() === -1) {
      if (response.data?.id) {
        this.router.navigate(['/admin/campaigns/show', response.data?.id]);
      } else {
        this.data.reset();
      }
    }
  }

  createBudgetGroup(): FormGroup {
    const group = this.formBuilder.group({
      id: ['', [Validators.required]],
      name: ['', [Validators.required]],
      budgets: this.formBuilder.group({
        fieldboards: [0, [Validators.required]],
        key_moments: [0, [Validators.required]],
        near_goal: [0, [Validators.required]],
      }),
    });

    return group;
  }

  get budgets(): FormArray {
    return this.data.get('budgets') as FormArray;
  }

  addBudget(): void {
    this.budgets.push(this.createBudgetGroup());
  }

  // ! HADRCODED FROM SERVICE /catalogs/broadcasts, 1 total, 2 fieldboards, 3 key moments and 4 near goal
  override getParams() {
    console.debug(this.data);
    console.debug(this.data.value);

    const params = this.data.value;

    return params;
  }

  // ! HADRCODED FROM SERVICE /catalogs/broadcasts, 1 total, 2 fieldboards, 3 key moments and 4 near goal
  setParams(params: any) {
    const { budgets, clients, ...data } = params;

    const formData: any = {
      ...data,
    };

    if (clients) {
      formData['clients'] = clients.map((type: any) => type.id);
    }

    this.data.patchValue(formData);

    if (budgets && Array.isArray(budgets) && budgets.length > 0) {
      console.debug(budgets);

      budgets.forEach((budget: any) => {
        console.debug(budget);
        const nBudget = this.createBudgetGroup();
        nBudget.patchValue(budget);

        this.budgets.push(nBudget);

        if (budget.budgets) {
          if (budget.budgets.fieldboards) {
          }

          if (budget.budgets.key_moments) {
          }

          if (budget.budgets.near_goal) {
          }
        }
      });
    } else {
      this.addBudget();
    }

    this.data.updateValueAndValidity();
  }
}
