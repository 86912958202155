import { Main } from '@shared/models/Main.model';

export class CampaignComments {
  general: string = '';
  fieldboards: string = '';
  'key-moments': string = '';
  'near-goal': string = '';
  'earned-media': string = '';
  'playoffs': string = '';
}

export class Budget {
  id: number = -1;
  name: string = '';
  budgets: Budgets = new Budgets();
}

export class Budgets {
  fieldboards: number = 0;
  key_moments: number = 0;
  near_goal: number = 0;
}

export class Campaign extends Main {
  id: number = -1;
  name: string = '';
  budget: number = 0;
  budgets: Budget[] = [];
  description: string = '';
  image: string = '';
  property_id: number = 0;
  property_other: string | null = null;
  created_at: string | null = null;
  updated_at: string | null = null;
  deleted_at: string | null = null;
  clients: Client[] = [];
  comments: CampaignComments = new CampaignComments();

  [key: string]: any;
}

export class Broadcast {
  broadcast_type_id: number = -1;
  amount: number = 0;
  comments: string | null = null;
}

export class Client {
  id: number = -1;
  name: string = '';
  logo: string | null = null;
}
