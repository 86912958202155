import { Component, inject, signal, viewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Location, NgClass } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { Observable } from 'rxjs';

import { Campaign } from '@admin/models/Campaign.model';
import {
  ConfirmModalComponent,
  FormComponent,
  MainCardComponent,
  TitleComponent,
} from '@shared/components';
import { fadeInAnimation } from '@shared/animations/fade.animation';
import { RolesService } from '@services/roles.service';
import { CampaignType, Property } from '@admin/models/CampaignType.model';
import { SlugifyPipe } from '@shared/pipes/slugify.pipe';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@shared/components/breadcrumb/breadcrumb.component';

import { TypeFormComponent } from './type-form/type-form.component';

const GROUP = 'admin.types';

@Component({
  selector: 'app-campaigns-show',
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    RouterLink,

    NgSelectModule,

    TypeFormComponent,
    BreadcrumbComponent,
    MainCardComponent,
    TitleComponent,
  ],
  animations: [fadeInAnimation],
  templateUrl: './campaigns-show.component.html',
  styleUrl: './campaigns-show.component.scss',
  providers: [SlugifyPipe],
})
export class CampaignsShowComponent extends FormComponent {
  private location = inject(Location);
  private modalService = inject(NgbModal);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  protected roles = inject(RolesService);
  slugify = inject(SlugifyPipe);

  breadcrumbs: BreadcrumbItem[] = [];
  // * -1 = nothing state, 0 > item id to edit
  editing: number = -1;
  creating: boolean = false;
  campaign: Campaign = new Campaign();
  title: string = 'CLIENT NAME / CAMPAIGN NAME';
  subtitle: string = '';

  campaignId: number = -1;
  clientId: number = -1;
  // ! For Quearters -> months
  typeId: number = -1;
  propertyId: number = -1;

  items: CampaignType[] = [];
  properties: Property[] = [];
  page: number = 1;
  limit: number = 11;

  // ! ACTIONS CONTROLS
  canCreate = signal(false);
  canDelete = signal(false);
  canEdit = signal(false);
  canUpdate = signal(false);
  // ! ACTIONS CONTROLS

  createForm = viewChild<TypeFormComponent>('new');
  editForm = viewChild<TypeFormComponent>('edit');

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.campaignId = Number(params.get('id') ?? -1);
      this.typeId = Number(params.get('period') ?? -1);
      this.propertyId = Number(params.get('property') ?? -1);
      this.clientId = Number(params.get('client') ?? -1);
      console.log(this.clientId);

      const data = this.route.snapshot.data;
      this.subtitle = data['title'] || '';

      if (this.loading) return;

      this.services = [this.api.call(`admin/campaigns/${this.campaignId}`)];

      if (this.typeId !== -1) {
        this.services.push(
          this.api.call(
            `admin/campaigns/${this.campaignId}/types?type_id=${this.typeId}`
          )
        );
      } else {
        this.services.push(
          this.api.call(`admin/campaigns/${this.campaignId}/types`)
        );
        this.services.push(this.api.call('admin/catalogs/properties'));
      }

      this.loadServices();
    });

    this.roles.roles$.subscribe((loaded) => {
      if (loaded) {
        this.canCreate.set(this.roles.can(GROUP, 'create'));
        this.canEdit.set(this.roles.can(GROUP, 'edit'));
        this.canDelete.set(this.roles.can(GROUP, 'destroy'));
        this.canUpdate.set(this.roles.can(GROUP, 'update'));
      }
    });
  }

  override getServices(): Observable<any>[] {
    const services = [this.api.call(`admin/campaigns/${this.campaignId}`)];

    if (this.typeId !== -1) {
      services.push(
        this.api.call(
          `admin/campaigns/${this.campaignId}/types?type_id=${this.typeId}`
        )
      );
    } else {
      services.push(this.api.call(`admin/campaigns/${this.campaignId}/types`));
      this.services.push(this.api.call('admin/catalogs/properties'));
    }

    return services;
  }

  // /admin/campaigns/1/segments
  override onLoadServicesSuccess(responses: any[]): void {
    console.log(responses);

    if (responses[2] && responses[2].status === 200)
      this.properties = responses[2].data;

    if (responses[0].status === 200) {
      this.campaign = responses[0].data;

      let client: string | null = null;

      if (this.campaign.clients.length > 0 && this.clientId !== -1) {
        client =
          this.campaign.clients.find((client) => client.id === this.clientId)
            ?.name || null;
      }

      if (!client && this.campaign.clients.length > 0) {
        client = this.campaign.clients[0].name;
      }

      this.title = `${client ? client + ' / ' : ''}${this.campaign.name}`;

      this.subtitle = this.campaign.property_other
        ? this.campaign.property_other
        : this.properties.find((prop) => prop.id === this.campaign.property_id)
            ?.name || '';

      this.breadcrumbs = [
        {
          label: this.clientId !== -1 ? client || 'Clients' : 'Campaigns',
          url:
            this.clientId !== -1
              ? `/admin/campaigns/client/${this.clientId}`
              : '/admin/campaigns',
        },
        {
          label: this.title,
          url: '',
        },
      ];

      console.log(this.breadcrumbs);
    }

    if (responses[1].status === 200 && responses[1].data.rows) {
      const items: CampaignType[] = responses[1].data.rows.map((item: any) => {
        const nType = new CampaignType();
        console.log(item);

        nType.setData(item);

        return nType;
      });

      if (this.page === 1) {
        this.items = [...items];
      } else {
        this.items = [...this.items, ...items];
      }

      console.log(this.items);
    }

    if (this.propertyId !== -1) {
      this.loadProperties();
    }

    this.stopLoading();
  }

  getProperty(property: Property | null): Property {
    let nProperty = property ? property : new Property();

    console.log('entro');

    if (property?.property !== null && property?.property !== undefined) {
      console.log('searching: ', property?.property);

      nProperty = this.getProperty(property.property);
    }

    console.log('salio');

    console.log(nProperty);

    return nProperty;
  }

  loadProperties() {
    console.log(this.campaign);

    this.breadcrumbs[1].url = `/admin/campaigns/show/${this.campaignId}`;

    this.api
      .call(`admin/catalogs/properties/${this.propertyId}`)
      .subscribe((response) => {
        if (response && response.status === 200) {
          this.properties = response.data;

          if (
            this.properties[0] &&
            this.properties[0].property &&
            this.properties[0].property.name
          ) {
          }
          this.breadcrumbs.push({
            label: this.properties[0]!.property!.name,
            url: '',
          });
        }

        console.log(response.data);
        console.log(this.properties);
      });
  }

  loadMore() {
    this.page += 1;
    this.loadServices();
  }

  handlePrymary(item: CampaignType) {
    console.log('primary: ', item.id, this.editing);
    console.log(item);

    if (this.editing !== -1) {
      console.log(this.editForm);
      console.log(this.editForm());
      this.editForm()?.submit();
    } else {
      let route = '';

      if (this.typeId !== -1) {
        if (this.clientId !== -1) {
          route = `admin/campaigns/${this.campaignId}/period/${
            this.typeId
          }/client/${this.clientId}/${encodeURIComponent(
            this.slugify.transform(item.property?.name || 'type')
          )}/${item.id}`;
        } else {
          route = `admin/campaigns/${this.campaignId}/period/${
            this.typeId
          }/${encodeURIComponent(
            this.slugify.transform(item.property?.name || 'type')
          )}/${item.id}`;
        }
      } else {
        if (this.clientId !== -1) {
          route = `admin/campaigns/show/${this.campaignId}/period/${item.id}/property/${item.property.id}/client/${this.clientId}`;
        } else {
          route = `admin/campaigns/show/${this.campaignId}/period/${item.id}/property/${item.property.id}`;
        }
      }

      console.log('typeId: ', this.typeId);
      console.log('clientId: ', this.clientId);

      this.router.navigate([route]);
    }
  }

  generalReport() {
    this.router.navigate([
      this.typeId !== -1
        ? `/admin/campaigns/${this.campaignId}/general-report/${this.typeId}`
        : `/admin/campaigns/${this.campaignId}/general-report`,
    ]);
  }

  handleSubmit() {
    this.cancelNew();
    this.loadServices();
  }

  cancelNew() {
    this.creating = false;
    this.editing = -1;
  }

  createType() {
    this.creating = true;
    this.editing = -1;
  }

  editType(id: number) {
    this.creating = false;
    this.editing = this.editing === id ? -1 : id;
  }

  deleteModal(id: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      backdrop: 'static',
      centered: true,
      // backdrop: false,
    });

    modalRef.componentInstance.title = 'DELETE TYPE';
    modalRef.componentInstance.message =
      'Do you really want to delete the type?';
    modalRef.result.then(
      (result: any) => {
        if (result) {
          this.deleteSegment(id);
        }
      },
      (_reason) => {}
    );
  }

  deleteSegment(id: number) {
    if (this.loading) {
      return;
    }

    this.startLoading();

    this.api.call(`admin/types/${id}`, 'delete', {}).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.handleAlert('Type deleted successfully!', 'success');
        } else {
          this.handleAlert(
            Array.isArray(response.message)
              ? response.message.join(', ')
              : response.message
          );
        }

        this.loadServices();
      },
      error: (error) => {
        this.handleAlert('An unexpected error occurred!');
        console.error(error);
        this.stopLoading();
      },
    });
  }

  goBack(): void {
    this.location.back();
  }
}
