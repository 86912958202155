import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { es } from 'date-fns/locale';

import { routes } from './app.routes';
import { ApiInterceptor } from './interceptors/api-interceptor.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { CustomPaginatorIntl } from './shared/config/custom-paginator-intl';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  DateFnsAdapter,
  MAT_DATE_FNS_FORMATS,
} from '@angular/material-date-fns-adapter';
import { MessageService } from 'primeng/api';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([ApiInterceptor, ErrorInterceptor])),
    provideRouter(routes),
    MessageService,
    { provide: MatPaginatorIntl, useClass: CustomPaginatorIntl },
    { provide: MAT_DATE_LOCALE, useValue: es },
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
  ],
};
