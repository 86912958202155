import { Component, inject, output, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { forkJoin } from 'rxjs';
import { DateTime } from 'luxon';

import { FormComponent } from '@shared/components';
import { PdfFile } from '@admin/models/PdfFile.model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-crud-form',
  standalone: true,
  imports: [],
  template: '',
})
export class CrudFormComponent extends FormComponent {
  private location = inject(Location);
  route = inject(ActivatedRoute);
  router = inject(Router);

  created: boolean = false;
  dataId = signal(-1);
  group = signal('');
  readonly: boolean = false;
  isComponent: boolean = false;

  pdfFiles: PdfFile[] = [];
  currentTemplate = {
    name: '',
    file: '',
  };
  onAction = output<boolean>();

  ngOnInit(): void {
    this.stopLoading();

    this.route.data.subscribe((data) => {
      this.onRouteDataChange(data);
    });

    this.route.paramMap.subscribe((params) => {
      if (this.dataId() === -1) {
        this.dataId.set(Number(params.get('id') ?? -1));
      }

      this.initSettings();

      const parts = this.router.url.split('/');

      if (!this.endpoint) {
        this.endpoint = (
          this.dataId() !== -1 ? parts.slice(1, -2) : parts.slice(1, -1)
        ).join('/');

        this.group.set(this.endpoint.replace(/\//g, '.'));
      }

      console.debug(this.endpoint);
      console.debug(this.dataId());

      if (this.dataId() !== -1) {
        this.method = 'put';
        this.endpoint = `${this.endpoint}/${this.dataId()}`;
        this.endpoint = this.getFormatEndpoint();
        console.debug(this.endpoint);

        this.services.push(this.api.call(this.endpoint));
      }

      this.load();
    });
  }

  onRouteDataChange(data: any): void {}

  initSettings(): void {}

  // ! USED TO FORMAT A CRUD ENDPOINT AFTER CREATE IT
  getFormatEndpoint(): string {
    return this.endpoint;
  }

  load() {
    const services = !this.initialized
      ? [...this.services]
      : [...this.getServices()];

    if (services.length === 0) return;

    this.startLoading();

    forkJoin(services).subscribe({
      next: (responses) => {
        this.handleLoadResponse(responses);
        this.initialized = true;
        this.stopLoading();
      },
      error: (err) => {
        this.handleErrors(err);
        this.stopLoading();
      },
    });
  }

  handleLoadResponse(responses: any[]) {}

  handleFile() {}

  handleErrors(errors: any[]) {}

  override loadServices() {
    const services = !this.initialized
      ? [...this.services]
      : [...this.getServices()];

    if (this.services.length > 0) {
      this.startLoading();

      forkJoin(services).subscribe({
        next: (data) => {
          this.onLoadServicesSuccess(data);
        },
        error: (error) => {
          this.onLoadServicesError(error);
        },
      });
    }
  }

  cancel() {
    if (this.isComponent) {
      this.handleCloseModal(false);

      return;
    }

    const route = this.router.url
      .split('/')
      .splice(
        0,
        this.router.url.split('/').length - (this.dataId() !== -1 ? 2 : 1)
      )
      .join('/');

    this.router.navigate([route]);
  }

  // Date format: YYYY-MM-dd, time format hh:mm:ss
  getZeroDate(date: Date, time: string): string {
    const format = this.getFormattedDate(date) + ' ' + time;

    return new Date(format).toISOString();
  }

  getFormattedDate(date: Date): string {
    return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
  }

  override getParams() {
    return this.data.value;
  }

  override isValid(): boolean {
    this.data.markAllAsTouched();

    const valid: boolean = this.data.valid ?? false;

    if (!valid) {
      this.handleAlert('Fill in all required fields!');
    }

    return valid;
  }

  selectFile(file: any) {
    if (this.loadingService.getLoading()) return;

    this.startLoading();

    if (file) {
      this.currentTemplate.name = file.name;
      this.currentTemplate.file = file.contents;
      this.handleFile();
      this.stopLoading();
    }
  }

  resetFile() {
    this.currentTemplate = {
      name: '',
      file: '',
    };
  }

  selectDocument(file: any) {
    if (this.loadingService.getLoading()) return;

    this.startLoading();

    const target = file.target as HTMLInputElement;

    if (target) {
      const pdfFile: PdfFile = {
        id: -1,
        name: '',
        file: '',
      };
      const uploadedFile = (target.files as FileList)[0];

      if (!uploadedFile.name) {
        this.loadingService.stop();
        return;
      }

      // Max 3mb
      const maxSizeInBytes = 3 * 1024 * 1024;

      if (uploadedFile.size > maxSizeInBytes) {
        this.handleAlert('The file exceeds the allowed size of 3 MB');
        this.loadingService.stop();
        return;
      }

      pdfFile.name = uploadedFile.name;

      let fileReader = new FileReader();

      if (target.files && target.files.length > 0) {
        let file = target.files[0];
        fileReader.readAsDataURL(file);

        fileReader.onload = (event: any) => {
          pdfFile.file = event.target.result;
          this.pdfFiles.push(pdfFile);

          const pdf = document.getElementById('pdf-selector');

          if (pdf) {
            (pdf as HTMLInputElement).value = '';
          }
        };
      }
    }

    this.stopLoading();
  }

  deletePdf(index: number) {
    const pdf = document.getElementById('pdf-selector');
    const pdfFile = this.pdfFiles[index];

    if (pdfFile && pdfFile.id !== -1 && pdfFile.file.includes('http')) {
      this.startLoading();

      this.api
        .call(`${this.endpoint}/document/${pdfFile.id}`, 'delete', {})
        .subscribe({
          next: (response) => {
            if (response.status === 200) {
              this.pdfFiles.splice(index, 1);

              if (pdf) {
                (pdf as HTMLInputElement).value = '';
              }

              this.handleAlert('Document deleted successfully', 'success');
            } else {
              this.handleAlert(
                response.message.join(', ') ||
                  'An error occurred while deleting the document!'
              );
            }

            this.stopLoading();
          },
          error: (error) => {
            console.error(error);
            this.handleAlert('An error occurred while deleting the documents');
            this.stopLoading();
          },
        });
    } else {
      if (pdf) {
        (pdf as HTMLInputElement).value = '';
      }

      this.pdfFiles.splice(index, 1);
    }
  }

  override success(response: any): void {
    if (this.dataId() === -1) this.created = true;

    this.handleAlert(
      `¡${this.created ? 'Item created ' : 'Changes saved'} successfully!`,
      'success'
    );

    this.onSuccess(response);

    if (this.isComponent) {
      this.handleCloseModal(true);
    }

    this.stopLoading();
  }

  onSuccess(response: any) {}

  handleCloseModal(success: boolean = true) {
    if (this.isComponent) {
      this.onAction.emit(success);
    }
  }

  goBack(): void {
    if (this.isComponent) {
      this.onAction.emit(false);
    } else {
      this.location.back();
    }
  }
}
