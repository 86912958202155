import { TimeModel } from './Match.model';

export class NearGoal {
  id: number = -1;
  budget: number = 0;
  description: string = '';
  summary = {
    unitsReceived: 0,
    impressionsContracted: 0,
    impressionsDelivered: 0,
  };
  info = {
    contracted: 0,
    delivered: 0,
    variance: 0,
  };
  matches: NearGoalVideo[] = [];

  setData(data: any) {
    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }
    }
  }

  init() {
    this.calculateCPMContrated();
    this.calculateCPMDelivered();
    this.calculateSummary();
    this.calculateVariance();
  }

  calculateSummary() {
    const [unitsReceived, delivered] = this.matches.reduce(
      (acc, match) => {
        return [acc[0] + match.unitsReceived, acc[1] + match.delivered];
      },
      [0, 0]
    );

    this.summary.unitsReceived = unitsReceived;
    this.summary.impressionsDelivered = delivered;
  }

  // Presupuesto ÷ Impresiones Contrated × 1,000 = CPM
  calculateCPMContrated() {
    if (this.summary.impressionsContracted < 0) {
      console.error('no valid');

      return;
    }

    this.info.contracted =
      (this.budget / this.summary.impressionsContracted) * 1000;

    this.calculateVariance();
  }

  // Presupuesto ÷ Impresiones Delivered × 1,000 = CPM
  calculateCPMDelivered() {
    if (this.summary.impressionsDelivered < 1) {
      return;
    }

    this.info.delivered =
      (this.budget / this.summary.impressionsDelivered) * 1000;

    this.calculateVariance();
  }

  calculateVariance() {
    if (this.info.contracted > 0 && this.info.delivered > 0) {
      this.info.variance = this.info.contracted - this.info.delivered;
    }
  }
}

export class NearGoalVideo {
  game: string = '';
  date: string = '';
  unitsReceived: number = 0;
  timeReceived: TimeModel = new TimeModel();
  viewership: number = 0;
  delivered: number = 0;
  leftMedia: string = '';
  rightMedia: string = '';

  constructor(data: any = {}) {
    for (const key in this) {
      if (data[key] !== undefined) {
        this[key] = data[key];
      }
    }

    if (data.timeReceived) {
      this.timeReceived = new TimeModel(data.timeReceived);
    }
  }
}
