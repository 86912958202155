<div class="row">
  <div class="col-12">
    <div class="w-100 table-responsive card mb-4">
      <table class="table text-center mb-0">
        <thead class="table-secondary">
          <tr>
            <th scope="col">Units Contracted</th>

            <th scope="col">Units Delivered</th>

            <th scope="col">Impressions Contracted</th>

            <th scope="col">Impressions Delivered</th>
          </tr>
        </thead>

        <tbody class="table-warning">
          <tr>
            <td class="col-3">{{ data.summary.unitsContracted }}</td>
            <td class="col-3">{{ data.summary.unitsDelivered }}</td>
            <td class="col-3">
              <input
                type="number"
                min="0"
                class="form-control text-center"
                id="impressions"
                placeholder="Impressions"
                [(ngModel)]="data.summary.impressionsContracted"
                (ngModelChange)="calculateCPMContrated()"
              />
            </td>
            <td class="col-3">{{ data.summary.impressionsDelivered }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="col-12">
    <div class="w-100 table-responsive card mb-4">
      <table class="table text-center mb-0">
        <thead class="table-secondary">
          <tr>
            <th scope="col">Total</th>
            <th scope="col">Contracted</th>
            <th scope="col">Delivered</th>
            <th scope="col">Variance</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td scope="col" class="col-3">CPM</td>
            <td scope="col" class="col-3">
              {{ data.info.contracted | currency }}
            </td>
            <td scope="col" class="col-3">
              {{ data.info.delivered | currency }}
            </td>
            <td scope="col" class="col-3">
              {{ data.info.variance | currency }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  @for (item of data.matches; track $index) {
  <div id="match-{{ $index + 1 }}" class="col-12 mb-4">
    <app-campaign-time-controller
      [data]="item"
      (onChange)="calculateTotal()"
      (onMedia)="handleFileMedia($event, $index)"
      (onDelete)="deleteMatch($index)"
    ></app-campaign-time-controller>
  </div>
  }
</div>
