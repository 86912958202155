import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApiService } from '@services/api.service';
import { LoadingService } from '@services/loading.service';
import { FormAlert } from '../alert/alert.component';
import { MessageService } from 'primeng/api';
import { Observable, forkJoin, map } from 'rxjs';

interface MatchFile {
  index: number;
  file: any[];
}

@Component({
  selector: 'app-form',
  standalone: true,
  imports: [],
  template: '',
})
export class FormComponent {
  endpoint: string = '';
  method: string = 'post';
  alertData: FormAlert | null = null;
  data: any = {};
  form: any;
  services: Observable<any>[] = [];
  initialized: boolean = false;
  loading: boolean = false;
  files: MatchFile[] = [];
  uploadUrl: string = 'admin/segments/upload';

  constructor(
    protected api: ApiService,
    protected loadingService: LoadingService,
    protected formBuilder: FormBuilder,
    private messageService: MessageService
  ) {
    this.constructorSettings();
  }

  constructorSettings(): void {}

  isValid(): boolean {
    return true;
  }

  getParams() {
    return this.data;
  }

  submit() {
    if (!this.isValid()) return;

    this.startLoading();

    const params = this.getParams();

    this.api.call(this.endpoint, this.method, params).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.success(response);
        } else {
          this.failed(response.message.join(', '));
        }
      },
      error: (error) => {
        this.failed(error);
      },
    });
  }

  handleMedia(): Observable<string[]> {
    this.startLoading();

    const services: Observable<string>[] = [];

    this.files.forEach((fileMath) => {
      fileMath.file.forEach((file: any) => {
        if (file instanceof File) {
          const formData = new FormData();
          formData.append('image', file);

          services.push(
            this.api.call(this.uploadUrl, 'post', formData).pipe(
              map((response: any) => {
                if (response.status === 200) {
                  return response.data.path;
                } else {
                  throw new Error(response.message || 'Upload failed');
                }
              })
            )
          );
        }
      });
    });

    return forkJoin(services).pipe(
      map((paths: string[]) => {
        this.stopLoading();
        return paths;
      })
    );
  }

  handleFileMedia(media: any[], index: number) {
    console.log(media);

    const exist = this.files.findIndex((file) => {
      return file.index === index;
    });

    if (exist !== -1) {
      this.files[exist] = {
        index,
        file: media,
      };
    } else {
      this.files.push({
        index,
        file: media,
      });
    }

    console.log(this.files);
  }

  success(response: any) {
    this.stopLoading();
  }

  failed(error: any) {
    this.handleAlert(
      typeof error === 'string'
        ? error || 'An error occurred!'
        : 'An error occurred!'
    );

    this.stopLoading();
  }

  getServices() {
    return this.services;
  }

  loadServices() {
    const services = !this.initialized
      ? [...this.services]
      : [...this.getServices()];

    if (this.services.length > 0) {
      this.startLoading();

      forkJoin(services).subscribe({
        next: (data) => {
          this.onLoadServicesSuccess(data);
          this.initialized = true;
        },
        error: (error) => {
          console.error(error);
          this.onLoadServicesError(error);
        },
      });
    }
  }

  onLoadServicesSuccess(responses: any[]): void {
    this.stopLoading();
  }

  onLoadServicesError(errors: any[]): void {
    this.stopLoading();
  }

  handleAlert(message: string, type: string = 'danger', title: string = '') {
    let toastr: any = {};

    switch (type) {
      case 'success':
        toastr = {
          severity: 'success',
          summary: title,
          detail: message,
        };

        break;

      case 'info':
        toastr = {
          severity: 'info',
          summary: title,
          detail: message,
        };

        break;

      case 'warning':
        toastr = {
          severity: 'warn',
          summary: title,
          detail: message,
        };

        break;

      case 'danger':
        toastr = {
          severity: 'error',
          summary: title,
          detail: message,
        };

        break;

      default:
        toastr = {
          severity: 'contrast',
          summary: title,
          detail: message,
        };

        break;
    }

    this.messageService.add(toastr);
  }

  startLoading() {
    this.loading = true;
    this.loadingService.start();
  }

  stopLoading() {
    this.loading = false;
    this.loadingService.stop();
  }
}
