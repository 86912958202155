import { Component, Input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DecimalPipe, NgClass } from '@angular/common';

import { NgSelectModule } from '@ng-select/ng-select';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { FormComponent } from '@shared/components';
import { NearGoalVideo } from '@admin/models/NearGoal.model';
import { MediaEditComponent } from '../media-edit/media-edit.component';

@Component({
  selector: 'app-near-goal-video',
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    DecimalPipe,
    NgSelectModule,
    NgbCollapseModule,
    MediaEditComponent,
  ],
  templateUrl: './near-goal-video.component.html',
  styleUrl: './near-goal-video.component.scss',
})
export class NearGoalVideoComponent extends FormComponent {
  @Input() override data: NearGoalVideo = new NearGoalVideo();

  onDelete = output<void>();
  onChange = output<void>();
  onMedia = output<any[]>();

  collapsed: boolean = false;
  media: any[] = [];

  deleteMatch() {
    this.onDelete.emit();
  }

  calculateTimeReceived() {
    if (this.data.unitsReceived === 0) return;

    this.data.timeReceived.setTime(this.data.unitsReceived * 30);

    this.calculateDelivered();
  }

  calculateDelivered() {
    this.data.delivered = this.data.unitsReceived * this.data.viewership;

    this.onChange.emit();
  }

  handleSelectMedia(media: any, index: number) {
    this.media[index] = media;
    console.log(this.media);

    this.onMedia.emit(this.media);
  }
}
