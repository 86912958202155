import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { fadeInAnimation } from '@shared/animations/fade.animation';

export interface BreadcrumbItem {
  label: string;
  url: string;
}

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [RouterLink, NgClass],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss',
  animations: [fadeInAnimation],
})
export class BreadcrumbComponent {
  breadcrumbs = input<BreadcrumbItem[]>([]);
}
