import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';

import { NgSelectModule } from '@ng-select/ng-select';

import { CampaignType, Property } from '@admin/models/CampaignType.model';
import { FormComponent } from '@shared/components';
import { fadeInAnimation } from '@shared/animations/fade.animation';

@Component({
  selector: 'app-type-form',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgClass, NgSelectModule],
  templateUrl: './type-form.component.html',
  styleUrl: './type-form.component.scss',
  animations: [fadeInAnimation],
})
export class TypeFormComponent extends FormComponent {
  creating = input<boolean>(false);
  typeId = input<number>(-1);
  campaignId = input<number>(-1);
  properties = input<Property[]>([]);
  override data = input<CampaignType>();

  onSubmit = output<void>();

  override form = this.formBuilder.group({
    id: new FormControl<number>(-1, Validators.required),
    property_id: new FormControl<number | null>(null, Validators.required),
    property_other: new FormControl<string | null>(null),
    budgets: this.formBuilder.group({
      fieldboards: [0, [Validators.required]],
      key_moments: [0, [Validators.required]],
      near_goal: [0, [Validators.required]],
    }),
  });

  ngOnInit(): void {
    console.log(this.data());
    console.log(this.typeId());
    console.log(this.properties());
    if (!this.creating() && this.data() && this.data() !== undefined) {
      console.debug(this.data());

      this.form.patchValue(this.data() as CampaignType);

      if (this.data()?.property?.id) {
        this.form
          .get('property_id')
          ?.setValue(this.data()?.property?.id || null);
      }
    }

    this.form.get('property_id')?.valueChanges.subscribe((type) => {
      this.updateDateValidator(type);
    });
  }

  private updateDateValidator(type: number | null): void {
    const propertyControl = this.form.get('property_other');

    propertyControl?.clearValidators();

    if (type === -1) {
      propertyControl?.setValidators([Validators.required]);
    }

    propertyControl?.updateValueAndValidity();
  }

  override isValid(): boolean {
    console.debug(this.form?.value);

    if (!this.data()) {
      if (
        this.form?.get('property_id')?.valid ||
        this.form?.get('property_other')?.value
      ) {
        return true;
      }
    }

    return this.form.valid ?? false;
  }

  override getParams() {
    const params: any = { ...this.form.value };

    if (params['property_id'] === -1) {
      params['property_id'] = null;
      delete params['property_id'];
    }

    if (this.typeId() !== -1) {
      params['type_id'] = this.typeId();
    }

    console.log(params);

    if (this.creating()) {
      this.method = 'post';
      this.endpoint = `admin/campaigns/${this.campaignId()}/types`;

      delete params['id'];
    } else {
      this.method = 'put';
      this.endpoint = `admin/types/${params['id']}`;
    }

    return params;
  }

  handleSubmit(): void {
    if (this.data()) {
      return;
    }

    this.submit();
  }

  override success(_response: any): void {
    this.handleAlert(
      `¡${
        this.form.get('id')?.value === -1 ? 'Item created ' : 'Changes saved'
      } successfully!`,
      'success'
    );

    this.endpoint = '';
    this.onSubmit.emit();
  }
}
