import { Routes } from '@angular/router';

import { environment } from '@environments';
import { adminGuard } from '@shared/guards/admin.guard';

import {
  CampaignsComponent,
  CampaignsFormComponent,
  CampaignsShowComponent,
  ChannelsComponent,
  ChannelsFormComponent,
  ClientsComponent,
  ClientsFormComponent,
  HomeComponent,
  PermissionsComponent,
  PermissionsFormComponent,
  PrivacyComponent,
  ProfileComponent,
  PropertiesComponent,
  PropertiesFormComponent,
  ReportsComponent,
  ReportsFormComponent,
  RolesComponent,
  RolesFormComponent,
  SegmentFormComponent,
  SegmentsComponent,
  TermsComponent,
  UsersComponent,
  UsersFormComponent,
} from './pages';
import { GeneralReportComponent } from './components';
import { ReportType } from './enums/ReportType.enum';

export const adminRoutes: Routes = [
  // * CRUD ROUTES
  {
    path: 'campaigns',
    title: `Campaigns - ${environment.title}`,
    canActivate: [adminGuard],
    data: { requiredRoles: ['admin.campaigns.index'], breadcrumb: 'Campaigns' },
    children: [
      {
        path: '',
        component: CampaignsComponent,
      },
      {
        path: 'client/:clientId',
        component: CampaignsComponent,
      },
      {
        path: 'create',
        component: CampaignsFormComponent,
      },
      {
        path: 'edit/:id',
        component: CampaignsFormComponent,
      },
      {
        path: 'show/:id',
        component: CampaignsShowComponent,
      },
      {
        path: 'show/:id/client/:client',
        component: CampaignsShowComponent,
      },
      {
        path: 'show/:id/period/:period/property/:property',
        component: CampaignsShowComponent,
      },
      {
        path: 'show/:id/period/:period/property/:property/client/:client',
        component: CampaignsShowComponent,
      },
      {
        path: ':id',
        children: [
          {
            path: 'general-report/period/:period/type/:typeId',
            component: GeneralReportComponent,
            data: {
              type: ReportType.month,
            },
          },
          {
            path: 'general-report/:typeId',
            component: GeneralReportComponent,
            data: {
              type: ReportType.quarter,
            },
          },
          {
            path: 'general-report',
            component: GeneralReportComponent,
            data: {
              type: ReportType.general,
            },
          },
          {
            path: 'type/:typeId',
            component: SegmentsComponent,
          },
          {
            path: ':type/:typeId',
            component: SegmentsComponent,
          },
          {
            path: 'period/:period/client/:client/:type/:typeId',
            component: SegmentsComponent,
          },
          {
            path: 'period/:period/:type/:typeId',
            component: SegmentsComponent,
          },
          {
            path: ':type/:typeId/segment/:segmentId',
            component: SegmentFormComponent,
          },
          {
            path: ':type/:typeId/client/:client/segment/:segmentId',
            component: SegmentFormComponent,
          },
        ],
      },
    ],
  },
  {
    path: 'channels',
    title: `Channels - ${environment.title}`,
    canActivate: [adminGuard],
    data: { requiredRoles: ['admin.channels.index'] },
    children: [
      {
        path: '',
        component: ChannelsComponent,
      },
      {
        path: 'create',
        component: ChannelsFormComponent,
      },
      {
        path: 'edit/:id',
        component: ChannelsFormComponent,
      },
      {
        path: 'show/:id',
        component: ChannelsFormComponent,
        data: { readonly: true },
      },
    ],
  },
  {
    path: 'clients',
    title: `Clients - ${environment.title}`,
    canActivate: [adminGuard],
    data: { requiredRoles: ['admin.clients.index'] },
    children: [
      {
        path: '',
        component: ClientsComponent,
      },
      {
        path: 'create',
        component: ClientsFormComponent,
      },
      {
        path: 'edit/:id',
        component: ClientsFormComponent,
      },
      {
        path: 'show/:id',
        component: ClientsFormComponent,
        data: { readonly: true },
      },
    ],
  },
  {
    path: 'permissions',
    title: `Permissions - ${environment.title}`,
    canActivate: [adminGuard],
    data: { requiredRoles: ['admin.permissions.index'] },
    children: [
      {
        path: '',
        component: PermissionsComponent,
      },
      {
        path: 'create',
        component: PermissionsFormComponent,
      },
      {
        path: 'edit/:id',
        component: PermissionsFormComponent,
      },
      {
        path: 'show/:id',
        component: PermissionsFormComponent,
        data: { readonly: true },
      },
    ],
  },
  {
    path: 'properties',
    title: `Properties - ${environment.title}`,
    canActivate: [adminGuard],
    data: { requiredRoles: ['admin.properties.index'] },
    children: [
      {
        path: '',
        component: PropertiesComponent,
      },
      {
        path: 'create',
        component: PropertiesFormComponent,
      },
      {
        path: 'edit/:id',
        component: PropertiesFormComponent,
      },
      {
        path: 'show/:id',
        component: PropertiesFormComponent,
        data: { readonly: true },
      },
    ],
  },
  {
    path: 'roles',
    title: `Roles - ${environment.title}`,
    canActivate: [adminGuard],
    data: { requiredRoles: ['admin.roles.index'] },
    children: [
      {
        path: '',
        component: RolesComponent,
      },
      {
        path: 'create',
        component: RolesFormComponent,
      },
      {
        path: 'edit/:id',
        component: RolesFormComponent,
      },
      {
        path: 'show/:id',
        component: RolesFormComponent,
        data: { readonly: true },
      },
    ],
  },

  {
    path: 'reports',
    title: `Reports - ${environment.title}`,
    canActivate: [adminGuard],
    data: { requiredRoles: ['admin.reports.index'] },
    children: [
      {
        path: '',
        component: ReportsComponent,
      },
      {
        path: 'create',
        component: ReportsFormComponent,
      },
      {
        path: 'edit/:id',
        component: ReportsFormComponent,
      },
      {
        path: 'show/:id',
        component: ReportsFormComponent,
        data: { readonly: true },
      },
    ],
  },
  {
    path: 'users',
    title: `Users - ${environment.title}`,
    canActivate: [adminGuard],
    data: { requiredRoles: ['admin.users.index'] },
    children: [
      {
        path: '',
        component: UsersComponent,
      },
      {
        path: 'create',
        component: UsersFormComponent,
      },
      {
        path: 'edit/:id',
        component: UsersFormComponent,
      },
      {
        path: 'show/:id',
        component: UsersFormComponent,
        data: { readonly: true },
      },
    ],
  },
  // * CRUD ROUTES

  {
    path: 'privacy',
    component: PrivacyComponent,
    title: `Privacy policy - ${environment.title}`,
  },
  {
    path: 'home',
    component: HomeComponent,
    title: `Home - ${environment.title}`,
  },
  {
    path: 'settings',
    component: ProfileComponent,
  },
  {
    path: 'terms',
    component: TermsComponent,
    title: `Terms - ${environment.title}`,
  },
  {
    path: '**',
    redirectTo: 'home',
  },
];
