<div class="row">
  <div class="col-12 mb-3 mb-lg-4">
    <h4 class="fw-bold">SUMMARY</h4>

    <div class="w-100 table-responsive card mb-4">
      <table class="table text-center fw-bold mb-0">
        <thead class="table-dark text-center">
          <tr>
            <th scope="col">Totals</th>
            <th scope="col">Contracted</th>
            <th scope="col">Delivered</th>
            <th scope="col">Variance</th>
          </tr>
        </thead>
        <tbody class="table-warning">
          <tr>
            <td>Units</td>
            <td>{{ summary.units.contracted | number : '1.3-3' }}</td>
            <td>{{ summary.units.delivered | number : '1.3-3' }}</td>
            <td>{{ summary.units.variance | number : '1.3-3' }}</td>
          </tr>

          <tr>
            <td>Impressions</td>
            <td>{{ summary.impressions.contracted | number : '1.3-3' }}</td>
            <td>{{ summary.impressions.delivered | number : '1.3-3' }}</td>
            <td>{{ summary.impressions.variance | number : '1.3-3' }}</td>
          </tr>

          <tr>
            <td>CPM</td>
            <td>{{ summary.cpm.contracted | currency }}</td>
            <td>{{ summary.cpm.delivered | currency }}</td>
            <td>{{ summary.cpm.variance | currency }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h5 class="fw-bold">Comments</h5>
    <textarea
      id="campaign-comments"
      class="form-control"
      style="min-height: 100px"
      [(ngModel)]="data().campaign.comments.general"
    ></textarea>
  </div>

  <div class="col-12 px-0">
    <div class="row gy-3 gy-lg-4">
      @for (item of sections; track $index) {
      <div class="col-12">
        <h4 class="fw-bold">{{ item.title }}</h4>

        <div class="w-100 table-responsive card mb-4">
          <table class="table table-striped text-center mb-0">
            <thead class="table-secondary">
              <tr>
                @for (col of item.cols; track $index) {
                <th scope="col">{{ col }}</th>
                }
              </tr>
            </thead>
            <tbody>
              @for (row of item.rows; track $index) {
              <tr>
                @for (data of row; track $index) {
                <td>
                  @if ($index > 0 && data !== '-') {
                  {{
                    row[0] === 'CPM'
                      ? (data | currency)
                      : (data | number : '1.3-3')
                  }}
                  } @else { {{ data }} }
                </td>
                }
              </tr>
              }
            </tbody>
          </table>
        </div>

        <h5 class="fw-bold">Comments</h5>

        <textarea
          [id]="'table-comments-' + $index"
          class="form-control"
          style="min-height: 100px"
          [(ngModel)]="data().campaign.comments[item.comentControl]"
        ></textarea>
      </div>
      }
    </div>
  </div>
</div>
