import { NgModule } from '@angular/core';
import { DatePipe, NgClass } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';

import { AlertComponent, TitleComponent } from '@shared/components';
import { CrudHeaderComponent } from '../crud-header/crud-header.component';
import { SearchBarComponent } from '../search-bar/search-bar.component';

@NgModule({
  declarations: [],
  imports: [
    NgClass,
    DatePipe,
    RouterModule,

    MatPaginatorModule,
    MatIconModule,

    AlertComponent,
    CrudHeaderComponent,
    SearchBarComponent,
    TitleComponent,
  ],
  exports: [
    NgClass,
    DatePipe,
    RouterModule,

    MatPaginatorModule,
    MatIconModule,

    AlertComponent,
    CrudHeaderComponent,
    SearchBarComponent,
    TitleComponent,
  ],
})
export class CrudModule {}
