<form class="row gx-2 gy-3 gy-lg-4" [formGroup]="form" @fadeIn>
  <div class="col-12 mt-0">
    @if (typeId() === -1) {
    <p class="fw-bold mb-1">Quarter</p>

    <p class="mb-2">Select a quarter to add to the campaign.</p>
    } @else {
    <p class="fw-bold mb-1">Month</p>

    <p class="mb-2">Select a month to add to the quarter.</p>
    }

    <ng-select
      id="tag"
      appearance="outline"
      formControlName="property_id"
      placeholder="Select a property"
      [clearable]="false"
      [searchable]="false"
    >
      @for (item of properties(); track item.id) {
      <ng-option [value]="item.id">{{ item.name }}</ng-option>
      }
      <!--  -->
      @if (properties().length === 0) {
      <ng-option [value]="-1">Other</ng-option>
      }
    </ng-select>

    @if ( form.get('property_id')?.invalid && (form.get('property_id')?.dirty ||
    form.get('property_id')?.touched)) {
    <div id="tagValidation" class="text-danger">Property is required</div>
    }
  </div>
  <!--  -->
  @if (form.get('property_id')?.value === -1) {
  <div class="col-12" @fadeIn>
    <p class="fw-bold mb-1">New property</p>

    <p class="mb-2">If you need a new property write it below.</p>

    <input
      id="campaign_property"
      type="text"
      class="form-control"
      formControlName="property_other"
      [ngClass]="{
        'is-invalid':
          form.get('property_other')?.invalid &&
          (form.get('property_other')?.dirty ||
            form.get('property_other')?.touched)
      }"
    />

    @if ( form.get('property_other')?.invalid &&
    (form.get('property_other')?.dirty || form.get('property_other')?.touched))
    {
    <div id="validationProperty" class="invalid-feedback">
      Property is required.
    </div>
    }
  </div>
  }
  <!--  -->
  @if (typeId() !== -1) {
  <div class="col-12 px-0">
    <div class="row gx-2" formGroupName="budgets">
      <div class="col-12 mb-2">
        <p class="fw-bold mb-0">Budgets</p>
      </div>

      <div class="col-12" @fadeIn>
        <p class="fw-normal mb-1">Fieldboard</p>

        <div class="input-group mb-3">
          <span class="input-group-text">$</span>
          <input
            id="campaign_property"
            type="number"
            class="form-control"
            formControlName="fieldboards"
            [ngClass]="{
              'is-invalid':
                form.get('nType.fieldboards')?.invalid &&
                (form.get('nType.fieldboards')?.dirty ||
                  form.get('nType.fieldboards')?.touched)
            }"
          />
        </div>
        @if ( form.get('nType.fieldboards')?.invalid &&
        (form.get('nType.fieldboards')?.dirty ||
        form.get('nType.fieldboards')?.touched)) {
        <div id="validationProperty" class="invalid-feedback">
          Property is required.
        </div>
        }
      </div>

      <div class="col-12" @fadeIn>
        <p class="fw-normal mb-1">Key Moments</p>

        <div class="input-group mb-3">
          <span class="input-group-text">$</span>
          <input
            id="campaign_property"
            type="number"
            class="form-control"
            formControlName="key_moments"
            [ngClass]="{
              'is-invalid':
                form.get('nType.key_moments')?.invalid &&
                (form.get('nType.key_moments')?.dirty ||
                  form.get('nType.key_moments')?.touched)
            }"
          />
        </div>

        @if ( form.get('nType.key_moments')?.invalid &&
        (form.get('nType.key_moments')?.dirty ||
        form.get('nType.key_moments')?.touched)) {
        <div id="validationProperty" class="invalid-feedback">
          Property is required.
        </div>
        }
      </div>

      <div class="col-12" @fadeIn>
        <p class="fw-bold mb-1">Near Goal</p>

        <div class="input-group mb-3">
          <span class="input-group-text">$</span>
          <input
            id="campaign_property"
            type="number"
            class="form-control"
            formControlName="near_goal"
            [ngClass]="{
              'is-invalid':
                form.get('nType.near_goal')?.invalid &&
                (form.get('nType.near_goal')?.dirty ||
                  form.get('nType.near_goal')?.touched)
            }"
          />
        </div>

        @if ( form.get('nType.near_goal')?.invalid &&
        (form.get('nType.near_goal')?.dirty ||
        form.get('nType.near_goal')?.touched)) {
        <div id="validationProperty" class="invalid-feedback">
          Property is required.
        </div>
        }
      </div>
    </div>
  </div>

  }
</form>
