<div class="row gy-4 py-4 align-items-center">
  @if (preview) {
  <div class="col-12 text-center">
    <img [src]="preview" class="img-fluid" [alt]="'Image-'" />
  </div>
  }

  <div class="col-12">
    <div class="input-group">
      <button
        id="image-selector"
        class="btn btn-primary px-lg-3 px-xl-4 px-xxl-5"
        type="button"
        (click)="selectFile()"
      >
        {{ preview ? 'CHANGE' : 'SELECT' }}
      </button>

      <input
        type="text"
        class="form-control"
        aria-describedby="image-selector"
        [readOnly]="true"
        [disabled]="true"
        value="Choose an image..."
      />
    </div>
  </div>
</div>

<input
  #fileInput
  type="file"
  hidden
  (change)="onFileSelected($event)"
  accept="image/jpeg, image/png"
/>
