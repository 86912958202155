import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MatPaginatorModule } from '@angular/material/paginator';

import {
  ConfirmModalComponent,
  MainCardComponent,
  TitleComponent,
} from '@shared/components';
import { AlertComponent } from '@shared/components/alert/alert.component';
import { Pagination } from '@shared/models/Pagination.model';
import { SimpleElement } from '@admin/models/SimpleElement.model';
import { Campaign } from '@admin/models/Campaign.model';
import {
  CrudComponent,
  CrudHeaderComponent,
  SearchBarComponent,
} from '@admin/components';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@shared/components/breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-campaigns',
  standalone: true,
  imports: [
    RouterModule,
    MatPaginatorModule,

    AlertComponent,
    BreadcrumbComponent,
    ConfirmModalComponent,
    CrudHeaderComponent,
    MainCardComponent,
    SearchBarComponent,
    TitleComponent,
  ],
  templateUrl: './campaigns.component.html',
  styleUrl: './campaigns.component.scss',
})
export class CampaignsComponent extends CrudComponent {
  override data: Pagination<Campaign> = new Pagination(Campaign);
  properties: SimpleElement[] = [];
  breadcrumbs: BreadcrumbItem[] = [];
  clientId: number = -1;

  override settings(): void {
    this.services = [this.api.call('admin/catalogs/properties')];
    const isClients = this.route.snapshot.data['client'];
  }

  override handleLoadResponse(responses: any[]): void {
    const last = responses.length - 1;

    if (!this.initialized && responses[0].data)
      this.properties = responses[0].data;

    if (responses[last].data) {
      this.data.setData(responses[last].data);

      this.data.rows.forEach((campaign) => {
        campaign.image =
          campaign.clients.find((client) => client.logo)?.logo ||
          '/assets/images/interticket.png';
      });

      this.data.rows = this.data.rows.map((row) => {
        row['property'] = this.properties.find(
          (prop) => prop.id === row['property_id']
        );

        return row;
      });
    }

    this.stopLoading();
  }

  override getFormatEndpoint(): string {
    const id = this.route.snapshot.paramMap.get('clientId');

    this.crudRoute = 'admin/campaigns';

    return `admin/campaigns${id ? '?client_id=' + id : ''}`;
  }

  deleteModal(id: number) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
      backdrop: 'static',
      centered: true,
      // backdrop: false,
    });

    modalRef.componentInstance.title = 'DELETE CAMPAIGN';
    modalRef.componentInstance.message =
      'Do you really want to delete the campaign?';
    modalRef.result.then(
      (result: any) => {
        if (result) {
          this.deleteCampaign(id);
        }
      },
      (reason) => {}
    );
  }

  deleteCampaign(id: number) {
    //
    if (this.loading) {
      return;
    }

    this.startLoading();

    this.api.call(`admin/campaigns/${id}`, 'delete', {}).subscribe({
      next: (response) => {
        if (response.status === 200) {
          this.handleAlert('Campaign deleted successfully!', 'success');
        } else {
          this.handleAlert(
            Array.isArray(response.message)
              ? response.message.join(', ')
              : response.message
          );
        }

        this.load();
      },
      error: (error) => {
        this.handleAlert('An unexpected error occurred!');
        console.error(error);
        this.stopLoading();
      },
    });
  }

  override onPrimary(id: number) {
    const clientId = this.route.snapshot.paramMap.get('clientId');

    if (clientId) {
      this.router.navigate([`/admin/campaigns/show/${id}/client/${clientId}`]);
    } else {
      this.router.navigate([`/admin/campaigns/show/${id}`]);
    }
  }
}
